import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import Scrollspy from 'vue2-scrollspy';
import firebase from "firebase";
import 'firebase/auth'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import StatusIndicator from 'vue-status-indicator';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(StatusIndicator);
Vue.use(VueTelInput);
Vue.use(VueAxios, axios);

const firebaseConfig = {
  apiKey: "AIzaSyCpOH7Zd2yO6ljLs_KvmHrFOxGEIfcR-aA",
  authDomain: "eddcaller.com",
  projectId: "eddcaller",
  storageBucket: "eddcaller.appspot.com",
  messagingSenderId: "118446555285",
  appId: "1:118446555285:web:56e99f4968e3fa621d9b9c",
  measurementId: "G-YJX5RP9WZZ"
};

//Your Firebase Config Api key Here
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



var VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false

import "@/assets/scss/style.scss";
import "@/assets/css/materialdesignicons.min.css";

Vue.use(VueYoutube)

Vue.use(Scrollspy);
Vue.use(VueScrollTo)

// Install BootstrapVue
Vue.use(BootstrapVue)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
